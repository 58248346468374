<!--表单-->
<template>
  <div>
    <a-form-model :model="entity" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }" :rules="rules" ref="form">
      <a-form-model-item label="用户名" prop="username" v-show="!isEdit">
        <a-input v-model="entity.username" allow-clear placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="密码" prop="passwd" v-show="!isEdit">
        <a-input-password v-model="entity.passwd" visibilityToggle allow-clear placeholder="请输入"/>
      </a-form-model-item>
      <a-form-model-item label="真实名称" prop="realname" v-show="!isEdit">
        <a-input v-model="entity.realname" allow-clear placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="手机号码" prop="mobileno" v-show="!isEdit">
        <a-input v-model="entity.mobileno" allow-clear placeholder="请输入"></a-input>
      </a-form-model-item>
      <a-form-model-item label="企业微信" prop="weixin">
        <a-select
          mode="combobox"
          v-model="value"
          labelInValue
          placeholder="请输入名字并选择"
          style="width: 100%"
          :filter-option="false"
          :not-found-content="fetching ? undefined : null"
          @search="fetchUser"
          @change="handleChange"
        >
          <a-spin v-if="fetching" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in weixinData" :key="d.value">
            {{ d.text }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="邮箱" prop="email" v-show="!isEdit">
        <a-input v-model="entity.email" allow-clear placeholder="请输入"></a-input>
      </a-form-model-item>
<!--      <a-form-model-item label="状态" prop="status">
        <a-radio-group v-model="entity.status" button-style="solid">
          <a-radio-button v-for="item in $dict.store.OPERATOR_STATUS" :key="item[0]" :value="item[0]">{{item[1]}}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>-->
      <a-form-model-item label="备注" prop="remark" v-show="!isEdit">
        <a-textarea v-model="entity.remark" allow-clear placeholder="备注" :auto-size="{ minRows: 3, maxRows: 5 }"/>
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 12, offset: 6 }">
        <a-button type="primary" @click="save" :loading="loading">保存</a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<style rel="stylesheet/less" scoped lang="less">
</style>
<script>
  import _ from "lodash";

  let defaultEntity = {
    username: '',
    passwd: '',
    realname: '',
    mobileno: '',
    status: 1
  }

  export default {
    components: {},
    data() {
      this.lastFetchId = 0;
      this.fetchUser = _.debounce(this.fetchUser, 800);
      return {
        weixinData: [],
        storeData: [],
        value: '',
        isEdit: false,
        fetching: false,
        entity: {...defaultEntity},
        loading: false,
        rules: {
          username: [{required: true, message: '用户名不能为空', trigger: 'blur'}],
          passwd: [{required: true, message: '初始化密码不能为空', trigger: 'blur'}],
          realname: [{required: true, message: '真实名称不能为空', trigger: 'blur'}],
          // status: [{required: true, message: '请选择', trigger: 'blur'}],
        }
      };
    },
    computed: {},

    methods: {
      fetchUser(value) {
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.weixinData = [];
        this.fetching = true;
        if (!value) {
          return false
        }
        this.$api.OperatorService.weChatWorkInfo(value)
            .then(res => {
              console.log(res)
              if (fetchId !== this.lastFetchId) {
                // for fetch callback order
                return;
              }
              const data = res.map(item => ({
                text: item.employee_name,
                value: item.userid,
              }));
              this.weixinData = data;
              this.storeData = data;
              this.fetching = false;
            })
      },
      handleChange(value) {
        Object.assign(this, {
          value,
          weixinData: [],
          fetching: false,
        });
      },
      save() {
        console.log(this.value)
        let index = this.findElem(this.storeData, 'value', this.value.key)
        if (index < 0 && this.value.key) {
          this.$message.warning('请选择正确的企业微信')
          return false
        }
        this.entity.qyAuthorId = this.value.key
        this.$refs.form.validate((valid) => {
          if (!valid) {
            return;
          }
          // 编辑调用
          if (this.isEdit) {
            
          }
          this.loading = true;
          this.$api.OperatorService.save(this.entity)
            .then(res => {
              console.log('查看放回：',res)
              if(res.errcode){
                this.$message.error(res.errmsg)
                this.loading = false;
                return ;
              }else{
                this.entity.id = res;
                this.loading = false;
                this.$emit('saved', res);
              }
            }, e => {
            this.loading = false;
          });
        });
      },
      findElem (array, attr, val) {
          for (var i = 0; i < array.length; i++) {
            console.log(1, array[i][attr])
            console.log(2, val)
              if (array[i][attr] == val) {
                  return i; //返回当前索引值
              }
          }
          return -1;
      },
      init(options = {}) {
        console.log(options)
        this.$refs.form.resetFields();
        this.value = {
          key: '',
          label: ''
        }
        this.entity = {...defaultEntity};
        this.isEdit = false
        if (options.record) {
          this.isEdit = true
          this.entity = options.record;
          this.value = {
            key: options.record.qy_user_id,
            label: options.record.qy_user_id
          }
        }
      }
    },
    created() {
    },
    mounted() {
      this.$on("init", this.init);
    }
  };
</script>
