<template>
  <div>
    <div class="panel panel-default panel-search">
      <a-form-model layout="inline">
        <a-form-model-item label="用户名">
          <a-input v-model="query.param.username" allow-clear placeholder="用户名"></a-input>
        </a-form-model-item>
        <a-form-model-item label="真实名称">
          <a-input v-model="query.param.realname" allow-clear placeholder="真实名称"></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="search">查询</a-button>
          <a-button type="link" @click="clear" style="margin-left:8px">重置</a-button>
        </a-form-model-item>
      </a-form-model>

      <div class="tools">
        <a-button type="primary" @click="create" icon="plus">新增</a-button>
      </div>
    </div>
    <v-table ref="table" :columns="columns" :remote="remote" :query="query">
<!--      <div slot="status" slot-scope="{row}">
        {{$dict.getText(row.status,$dict.store.OPERATOR_STATUS)}}
        <a-tooltip>
          <template slot="title">{{row.statusMsg}}</template>
          <a-icon type="info-circle" v-show="row.status==0" style="color:red" />
        </a-tooltip>
      </div>-->
      <div slot="state" slot-scope="{row}">
        {{row.state == 1 ? '已绑定' : '未绑定'}}
      </div>
      <div slot="operation" slot-scope="{row}">
        <a type="link" @click="edit(row)">编辑</a>
        <!-- <a-divider type="vertical" />
        <a class="red" @click="del(row)">删除</a> -->
      </div>
    </v-table>
    <a-drawer
            title="操作员编辑"
            :width="520"
            :visible="formDrawVisible"
            :body-style="{ paddingBottom: '80px' }"
            @close="()=>{formDrawVisible = false;}"
    >
      <FormPanel ref="formPanel" @saved="onFormSaved"></FormPanel>
    </a-drawer>
  </div>
</template>
<style rel="stylesheet/less" scoped lang="less">
</style>
<script>

import FormPanel from "./Form";
let defaultQuery = {
  orderBys: "createTime|desc,id|desc",
  param: {
    username:'',
    realname:'',
    deleted: false
  }
};
export default {
  components: { FormPanel },
  data() {
    return {
      formDrawVisible: false,
      orderDateRange: [],
      query: _.cloneDeep(defaultQuery),
      columns: [
        { title: '用户名', dataIndex: 'username', width: 150 },
        { title: '真实名称', dataIndex: 'realname', width: 150 },
        { title: '手机号码', dataIndex: 'mobileno', width: 150 },
        { title: '邮箱', dataIndex: 'email', width: 150 },
        // { title: '状态', dataIndex: 'status', width: 80, scopedSlots: { customRender: "status" } },
        { title: '备注', dataIndex: 'remark', width: 200 },
        { title: '企业微信绑定状态', scopedSlots: { customRender: 'state' }, width: 200 },
        { title: "操作", scopedSlots: { customRender: 'operation' }, width: 100 }
      ]
    };
  },
  computed: {},

  methods: {
    create() {
      this.formDrawVisible = true;
      this.$nextTick(() => {
        this.$refs.formPanel.$emit("init", {});
      });
    },
    edit(row) {
      this.formDrawVisible = true;
      this.$nextTick(() => {
        this.$refs.formPanel.$emit("init", { record: { ...row } });
      });
    },
    del(row) {
      let self = this;
      this.$confirm({
        title: '提示',
        content: '确定删除此条记录吗？',
        onOk() {
          self.$api.OperatorService.delete(row.id).then(rsp => {
            self.search();
            self.$message.info("删除成功！");
          });
        }
      });
    },
    onFormSaved(){
      this.formDrawVisible = false;
      this.$message.info("保存成功！");
      this.search();
    },
    clear(){
      this.query = _.cloneDeep(defaultQuery);
      this.$nextTick(() => {
        this.search(true);
      });
    },
    init(options = {}) {
      this.search();
    },
    remote(qp){ //远程方法
      return this.$api.OperatorService.query(qp);
    },
    search(reset=false) {
      this.$refs.table.load({reset:reset});
    }
  },
  created() {},
  mounted() {
    this.$on("init", this.init);
  }
};
</script>
